.map-a5 {
    height: 100mm;
}
.map-a4 {
    height: 148mm;
}
.map-a4, .map-a5 {
    width: 100%;
}

/*
 * Fix grey bar in maps:
 * https://stackoverflow.com/questions/52625571/google-map-print-grey-color-on-print
 * https://bugs.chromium.org/p/chromium/issues/detail?id=426294
 */
.map-a4 div > img,
.map-a5 div > img {
    position: absolute;
}
.bubbleRebowl {
    color: #fff;
}
.bubbleRecup {
    text-shadow:
        1px 1px 2px #bee0da,
        -1px 1px 2px #bee0da,
        1px -1px 2px #bee0da,
        -1px -1px 2px #bee0da,
        1.5px 1.5px 2px #bee0da,
        -1.5px 1.5px 2px #bee0da,
        1.5px -1.5px 2px #bee0da,
        -1.5px -1.5px 2px #bee0da;
}
