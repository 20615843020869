.container {
    column-count: 2;
}
.firstPage .container {
    padding-top: 1em;
}

.partner {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: .5em;
    font-size: 0.8em;
    break-inside: avoid;

    /* Multi line */
    position: relative;

    /* one line trim
    clear: both;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    */
}
.page-a5 .partner {
    font-size: .65em;
}
.partner span {
    display: block;
    padding-left: 2.1em;
}
.partner strong {
    display: block;
    position: absolute;
    padding-top: 0.5em;
    top: 0;
    left: 0;
}

.page-a4 .firstPage {
    height: 104mm;
}
.page-a4 .firstPage .container {
    height: 88mm;
}
.page-a4 .fullPage {
    height: 297mm;
    padding: 20mm 0 10mm;
}
.page-a4 .fullPage .container {
    height: 261mm;
}

.page-a5 .firstPage {
    height: 79mm;
}
.page-a5 .firstPage .container {
    height: 65mm;
}
.page-a5 .fullPage {
    height: 210mm;
    padding: 15mm 0 8mm;
}
.page-a5 .fullPage .container {
    height: 181mm;
}
