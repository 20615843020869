@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700;900&display=swap');

/* General */
body, html {
  height: 100%;
  color: #1B1919;
  -webkit-print-color-adjust: exact !important;


  font-family: 'Montserrat', sans-serif;
}
* {
    box-sizing: border-box;
}
p, ul {
    font-size: 0.9rem;
}

/* Header */
h1 {
    font-weight: 900;
}
h2 {
    font-weight: 600;
}

.App-size-a5 h1 {
  font-size: 1.2em;
}
.App-size-a5 h2 {
  font-size: 1.2em;
}

.App-size-a4 .logo {
  width: 28mm;
  height: 28mm;
}
.App-size-a5 .logo {
  width: 18mm;
  height: 18mm;
}

header {
  float: right;
  text-align: right;
}
.App-size-a4 header {
  height: 35mm;
}
.App-size-a5 header {
  height: 23mm;
}

header h1 {
  margin-top: 0;
  margin-bottom: 0;
}
header h2 {
  margin-top: 0;
}

/* Footer */
.App-size-a4 footer {
  height: 16mm;
  padding-bottom: 10mm;
}
.App-size-a5 footer {
  height: 14mm;
  padding-bottom: 8mm;
}
footer strong {
  font-weight: 700;
}

/* Simulate page */
section {
  background-color: #bee0da;
}
@media print {
  body {
    background-color: #bee0da;
  }
}
.App-type-rebowl section {
  background-color: #386060;
  color: #fff;
}
.App-type-rebowl .logo {
  filter: invert(1);
}
.App-style-bw section {
  background-color: transparent;
  color: #000;
}
.App-size-a4 section {
  padding: 10mm;
  width: 210mm;

  padding-bottom: 0;
}
.App-size-a5 section {
  padding: 8mm;
  width: 148mm;

  padding-bottom: 0;
}

@page {
  margin: 0;
  padding: 0;
}
.App-size-a4 {
  @page {
    size:297mm 210mm;
  }
}
.App-size-a5 {
  @page {
    size:210mm 148mm;
  }
}
