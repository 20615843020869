@media print {
    .container {
        display: none;
    }
}
.container {
    background: #f2f2f2;
    padding: 1em;
}
.input {
    padding: .5em 1em;
    border: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    font-size: 1.1em;
    width: 100%;
    margin-top: .5em;
}
.input:first-child {
    margin-top: 0;
}
.input:focus {
    border: none;
    outline: none;
}
.select {
    padding: .5em 1em;
    border: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    font-size: 1.1em;
    width: 100%;
    margin-top: .5em;
}
.label {
    margin: 1em 0 -0.5em 0;
    display: block;
    font-size: 0.9em;
    font-weight: bold;
}
.label:first-child {
    margin-top: 0;
}
p + .select, p + .input {
    margin-top: -0.5em;
}

button {
    padding: .5em 1em;
    border: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    font-size: 1.1em;
    margin-top: 1em;
    background-color: #386060;
    color: #fff;
    cursor: pointer;
}
h3 {
    margin: 2em 0 0 0;
}
h3:first-child {
    margin-top: 0;
}

