body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}









input,select,button{
  font-family: "Montserrat",sans-serif;
}




.App{
    max-width: 210mm;
    margin: 0 auto;
}

.Controls_container___ni0C,
.Controls_container__m0AM2 {
    background: #f2f2f2;
    padding: 10mm !important;
}


button#karte-drucken {
    background-color: #222;
}

.App input, .App select{
    box-shadow: none;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    padding: 11px 11px;
    transition: all 0.2s ease-out;
}

.App input:focus, .App select:focus{
    border: 1px solid #222;
    box-shadow: 0 0 0 1px #222;
}


@media(min-width: 420mm){
  .App{
    width:420mm;
    max-width:420mm;
    margin-top:5mm;
    margin-bottom: 5mm;
  }
  .Controls_container___ni0C,
  .Controls_container__m0AM2{
    width: 205mm;
    margin-right:5mm;
    float: left;
  }
  .section-print{
    float:left;
  }
}
